<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>耗能管理</el-breadcrumb-item>
            <el-breadcrumb-item>用电管理</el-breadcrumb-item>
            <el-breadcrumb-item>电量曲线</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>所属酒店：</label>
                  <el-select v-model="hotelId"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in hotelList"
                           :key="item.id"
                           :label="item.hotelName"
                           :value="item.id"
                     ></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>对比年份：</label>
                  <el-date-picker
                        v-model="startYear"
                        type="year"
                        placeholder="选择年">
                  </el-date-picker>
                  -
                  <el-date-picker
                        class="m-right-10"
                        v-model="endYear"
                        type="year"
                        placeholder="选择年">
                  </el-date-picker>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <el-row class="electricity-main">
            <el-row id="electricity-line" style="height: 400px;margin: 0 auto"></el-row>
         </el-row>
      </el-row>
   </div>
</template>

<script>
export default {
  data() {
    return {
      hotelList: [
        { hotelName: "达易住大酒店", id: 1 },
      ],   // 酒店列表
      hotelId: "",        // 酒店ID
      startYear: '',      // 起始年份
      endYear: '',        // 截止年份
      lineOption: {
        title: {
          text: '年度用电情况（单位度）',
          textStyle: {
            color: '#409eff',
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['2020年用电', '2019年用电']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '2020年用电',
            type: 'line',
            stack: '总量',
            data: [8904, 7415, 9510, 8410, 4785, 5478, 9852, 6874, 8744, 6587, 5454, 4131]
          },
          {
            name: '2019年用电',
            type: 'line',
            stack: '总量',
            data: [7020, 7182, 8191, 6234, 7290, 9330, 4310, 5343, 7464, 10025, 7894, 9441]
          }
        ]
      },
    }
  },
  mounted() {
    let myChart = this.$echarts.init(document.getElementById("electricity-line"));
    myChart.setOption(this.lineOption);
    window.onresize=function(){
      myChart.resize()
    }
    this.getHotels()
  },
  methods: {
    // 获取酒店
    getHotels(){},
    // 处理搜索
    handleQuery(){}
  },
}
</script>

<style scoped>
   .electricity-main{
      padding: 60px 20px;
   }
</style>
